import React from 'react';

export default function Google() {
  return (
    <svg viewBox="0 0 3027 1024" height="50">
      <path d="M1288.347826 525.133913c0 142.135652-111.193043 246.873043-247.652174 246.873044s-247.652174-104.737391-247.652174-246.873044C793.043478 381.996522 904.236522 278.26087 1040.695652 278.26087s247.652174 103.735652 247.652174 246.873043z m-108.410435 0c0-88.82087-64.445217-149.593043-139.241739-149.593043S901.453913 436.313043 901.453913 525.133913c0 87.930435 64.445217 149.593043 139.241739 149.593044s139.241739-61.773913 139.241739-149.593044z" fill="#EA4335" />
      <path d="M1822.608696 525.133913c0 142.135652-111.193043 246.873043-247.652174 246.873044s-247.652174-104.737391-247.652174-246.873044c0-143.026087 111.193043-246.873043 247.652174-246.873043s247.652174 103.735652 247.652174 246.873043z m-108.410435 0c0-88.82087-64.445217-149.593043-139.241739-149.593043s-139.241739 60.772174-139.241739 149.593043c0 87.930435 64.445217 149.593043 139.241739 149.593044s139.241739-61.773913 139.241739-149.593044z" fill="#FBBC05" />
      <path d="M2334.608696 293.175652v443.213913c0 182.316522-107.52 256.77913-234.629566 256.779131-119.652174 0-191.666087-80.027826-218.824347-145.474783l94.386087-39.290435c16.806957 40.18087 57.989565 87.596522 124.326956 87.596522 81.363478 0 131.784348-50.198261 131.784348-144.695652v-35.506087h-3.784348c-24.264348 29.94087-71.012174 56.097391-130.003478 56.097391-123.436522 0-236.521739-107.52-236.521739-245.871304 0-139.353043 113.085217-247.763478 236.521739-247.763478 58.88 0 105.627826 26.156522 130.003478 55.206956h3.784348v-40.180869h102.956522z m-95.276522 232.848696c0-86.928696-57.989565-150.483478-131.784348-150.483478-74.796522 0-137.46087 63.554783-137.460869 150.483478 0 86.038261 62.664348 148.702609 137.460869 148.702609 73.794783 0 131.784348-62.664348 131.784348-148.702609z" fill="#4285F4" />
      <path d="M2504.347826 33.391304v723.478261h-105.73913V33.391304h105.73913z" fill="#34A853" />
      <path d="M2916.396522 606.386087l84.146087 56.097391c-27.158261 40.18087-92.605217 109.412174-205.690435 109.412174-140.243478 0-244.98087-108.410435-244.98087-246.873043 0-146.810435 105.627826-246.873043 232.848696-246.873044 128.111304 0 190.775652 101.954783 211.255652 157.050435l11.241739 28.048696-330.017391 136.681739c25.266087 49.530435 64.556522 74.796522 119.652174 74.796522 55.206957 0 93.495652-27.158261 121.544348-68.34087z m-259.005218-88.82087l220.605218-91.603478c-12.132174-30.831304-48.64-52.313043-91.603479-52.313043-55.095652 0-131.784348 48.64-129.001739 143.916521z" fill="#EA4335" />
      <path d="M392.793043 460.911304V356.173913H745.73913c3.450435 18.253913 5.231304 39.846957 5.231305 63.22087 0 78.58087-21.481739 175.749565-90.713044 244.980869-67.33913 70.121739-153.377391 107.52-267.353043 107.52C181.648696 771.895652 4.006957 599.81913 4.006957 388.563478 4.006957 177.307826 181.648696 5.231304 392.904348 5.231304c116.869565 0 200.125217 45.857391 262.678261 105.627826l-73.906087 73.906087c-44.855652-42.073043-105.627826-74.796522-188.883479-74.796521-154.267826 0-274.921739 124.326957-274.921739 278.594782 0 154.267826 120.653913 278.594783 274.921739 278.594783 100.062609 0 157.050435-40.18087 193.558261-76.688696 29.606957-29.606957 49.085217-71.902609 56.765218-129.669565l-250.323479 0.111304z" fill="#4285F4" />
    </svg>
  );
}